import "core-js/modules/es.array.push.js";
import { getUserBank, postCash } from "@/api";
export default {
  data() {
    return {
      chosePayItem: 0,
      money: "",
      pwd: "",
      accountType: "1",
      bankList: [],
      showPicker: true,
      choseBank: ""
    };
  },
  mounted() {
    this.$toast({
      message: '加载中...',
      type: "loading",
      forbidClick: true,
      duration: 0
    });
    getUserBank().then(res => {
      this.$closeToast();
      if (res.data.length < 1) {
        this.$toast("请先绑定银行卡");
        this.$router.push("/bank-cards");
        return;
      }
      this.bankList = res.data;
    });
  },
  methods: {
    chosePay(value) {
      this.chosePayItem = value;
    },
    // choseMoney (value) {

    //   this.money = value
    // },
    onConfirm() {
      if (!this.accountType) {
        this.$toast("请选择账户种类");
        return;
      }
      if (!this.choseBank) {
        this.$toast("请选择提现银行");
        return;
      }
      if (!this.money) {
        this.$toast("请输入金额");
        return;
      }
      if (!this.pwd) {
        this.$toast("请输入密码");
        return;
      }
      let data = {
        type: this.accountType,
        pwd: this.pwd,
        money: this.money,
        bank_id: this.choseBank
      };
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      postCash({
        ...data
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$store.dispatch("getUserInfo");
        this.pwd = "";
        this.money = "";
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};